import BibleVerseWidget from '../../components/BibleVerseWidget/BibleVerseWidget'
import AssistenteForm from '../../forms/formHappyDay/assistente/assistenteForm'
import './index.css'

export default function Home() {
  return (

    <>
      {/* <div className='home' >Paz do senhor Jesus!</div> */}
      {/* <BibleVerseWidget /> */}

      <AssistenteForm />
    </>
  )
}